import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Text, Button } from "theme-ui"
import { slide as Menu } from "react-burger-menu"
import Logo from "../components/Logo"
import "../styles/BurgerMenu.css"

export default function Header({ menuLinks, noButton }) {
  return (
    <>
      {/* Burger plugin: https://negomi.github.io/react-burger-menu/ */}
      <Menu noOverlay>
        <Box
          sx={{
            bg: "text",
            pt: 7,
            pl: [2, 4, 6],
            pr: 4,
            height: "100%",
            overflowY: "auto",
            "@media only screen and (min-width: 1600px)": {
              pl: "calc(((100vw - 1600px) / 2) + 64px)"
            }
          }}
        >
          <Box sx={{ mt: 4, mb: 5 }}>
            <Box>
              <Text variant="button" sx={{ mb: 2 }}>
                <Link to="/">Home</Link>
              </Text>
              {/* <Text variant="button" sx={{ mb: 2 }}>
                <Link to="/news">News</Link>
              </Text> */}
              <Text variant="button" sx={{ mb: 2 }}>
                <Link to="/store/digital-edition/">Play free</Link>
              </Text>
            </Box>
          </Box>
          {menuLinks.map((link) => (
            <Box key={link.name} sx={{ mb: 4 }}>
              <Text variant="label" sx={{ color: "grayMedium", mb: 2 }}>{link.name}</Text>
              {link.subMenu && link.subMenu.length > 0 ? (
                <Box>
                  {link.subMenu.map((subLink) => (
                    <Text key={subLink.name} variant="button" sx={{ mb: 2, color: "background" }}>
                      <Link to={subLink.link}>{subLink.name}</Link>
                    </Text>
                  ))}
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
      </Menu>
      
      <Box>
        <Grid variant="base" columns={3} sx={{ py: 5, px: [2, 4, 6], zIndex: 999 }}>
          <Grid />

          <Grid sx={{ justifyItems: "center", alignItems: "center", position: "relative" }}>
            <Logo
              height="48"
              fill="text"
              sx={{
                stroke: "background",
                strokeWidth: 30,
                position: "absolute",
                top: 1,
                left: "50%",
                transform: "translateX(-50%)",
                overflow: "visible",
                strokeLinejoin: "round"
              }}
            /> 
            <Link to="/">
              <Logo
                height="48"
                fill="text"
                sx={{
                  fill: "text",
                  position: "absolute",
                  top: 1,
                  left: "50%",
                  transform: "translateX(-50%)",
                  "&:hover": {
                    fill: "grayXDark"
                  }
                }}
              />
            </Link>
          </Grid>

          <Grid sx={{ justifyItems: "end", alignItems: "center", height: "67px" }}>
            {!noButton && 
              <Link to="/store">
                <Button variant="primary" sx={{ mr: -1, "@media only screen and (max-width: 768px)": { display: "none" } }}>Buy Quest</Button>
              </Link>
            }
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

