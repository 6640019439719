import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Flex, Text } from "theme-ui"
import Logo from "../components/Logo"
import TAGLogo from "../components/TAGLogo"
import NewsletterSignup from "../components/NewsletterSignup"

import endsheetImage from '../images/endsheet.jpg'
import {
  InstagramIcon,
  TwitterIcon,
  DiscordIcon } from "../components/Icon"

export default function Footer({ newsletter }) {
  return (
    <Box as="footer" sx={{ bg: "black", color: "white", mt: [7, 8] }}>
      <Box 
        sx={{ 
          width: "100%",
          backgroundImage: `url(${endsheetImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          height: newsletter ? "auto" : [200, 300],
          py: newsletter ? [7, 8] : 0,
          borderTop: "2px solid",
          borderColor: "black"
        }}
      >
        {newsletter && 
          <Box sx={{ bg: "background", p: [3, 5], maxWidth: 800, mx: "auto" }}>
            <NewsletterSignup />
          </Box>
        }
      </Box>
      
      <Grid variant="base" columns={[1, "1fr 4fr"]} sx={{ py: [7, 8] }}>
        <Box>
          <Link to={`/`}>
            <Logo width="120" fill="white" />
          </Link>
          
          <Flex sx={{ alignItems: "center", mt: 3, mb: [5, 0] }}>
            <a href="https://twitter.com/questrpg" target="_blank" rel="noreferrer">
              <Box sx={{ width: "24px", mr: 2 }}><TwitterIcon fill="white" /></Box>
            </a>
            <a href="https://www.instagram.com/questrpg/" target="_blank" rel="noreferrer">
              <Box sx={{ width: "24px", mr: 2 }}><InstagramIcon fill="white" /></Box>
            </a>
            <a href="https://discord.gg/UnhhyUB" target="_blank" rel="noreferrer">
              <Box sx={{ width: "24px", mr: 2 }}><DiscordIcon fill="white" /></Box>
            </a>
          </Flex>
        </Box>

        <Flex sx={{ flexDirection: ["column", "row"], justifyContent: "flex-end" }}>
          <Box sx={{ pl: [0, 3] }}>
            <Link to="/store"><Text as="p" variant="label" sx={{ mr: 2, mb: 2 }}>Store</Text></Link>
            <Link to="/support"><Text as="p" variant="label" sx={{ mr: 2, mb: 2 }}>Support</Text></Link>
          </Box>
          <Box sx={{ pl: [0, 3] }}>
            <Link to="/terms-and-conditions"><Text as="p" variant="label" sx={{ mr: 2, mb: 2 }}>Terms & Policies</Text></Link>
            <Link to="/ethics"><Text as="p" variant="label" sx={{ mr: 2, mb: 2 }}>Ethics Policy</Text></Link>
          </Box>
          <Box sx={{ pl: [0, 3] }}>
            <Link to="/social-guidelines"><Text as="p" variant="label" sx={{ mr: 2, mb: 2 }}>Social Guidelines</Text></Link>
            <Link to="/about-us"><Text as="p" variant="label" sx={{ mr: 2, mb: 2 }}>About Us</Text></Link>
          </Box>
        </Flex>  
      </Grid>
      
      <Grid variant="base" sx={{ py: 3 }}>
        <a href="https://www.adventure.enterprises" target="_blank" rel="noreferrer" style={{ width: 240 }}>
          <TAGLogo width="240" fill="grayMedium" />
        </a>
        <Box>
          <Text as="p" variant="label" sx={{ fontSize: "xs", color: "grayMedium", mb: 0 }}>© <a href="https://www.adventure.enterprises" target="_blank" rel="noreferrer">The Adventure Guild</a>, LLC, {new Date().getFullYear()}. All rights reserved.</Text>
          <Text as="p" variant="label" sx={{ fontSize: "xs", color: "grayMedium" }}>Illustration © Grim Wilkins / Marianna Learmonth, 2020</Text>
        </Box>
      </Grid>
    </Box>
  )
}