import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Box } from "theme-ui"
import Header from "./header"
import Footer from "./footer"
import "../styles/Layout.css"

const Layout = ({ children, noButton, showNewsletter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            subMenu {
              link
              name
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header menuLinks={data.site.siteMetadata.menuLinks} noButton={noButton} />
      <Box as="main">{children}</Box>
      <Footer newsletter={showNewsletter} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout