import React from "react"
import { Box } from "theme-ui"

export default function Logo({ width, fill, ...props }) {
  return (
    <Box as="svg" width={width} viewBox="0 0 359 162" fill="none" xmlns="http://www.w3.org/2000/svg" sx={{ display: "block", fill: fill }} {...props}>
      <Box as="path" fillRule="evenodd" clipRule="evenodd" d="M73.91 115.5L92.66 103.5L95.66 16.25L47.66 0L3.65997 29.75L0.98999 109.59L51.65 126.75L115.99 161.56L145.93 141.5L73.91 115.5ZM64.82 106.17L31.16 95.25L32.16 19.75L65.41 30L64.82 106.17Z" />
      <Box as="path" d="M94.53 53L117.22 36.79L130.77 48.54L129.37 103.04L146.65 110.04L147.82 58.79L137.91 52.44L159.03 36.79L172.11 48.79L170.94 105.04L183.59 116.31L162.8 130.11L146.89 118.29L126.66 125.5L104.41 117.42L106.48 58.54L94.53 53Z" />
      <Box as="path" fillRule="evenodd" clipRule="evenodd" d="M222.05 36.17L245.49 57.75L244.31 75L207.65 85.62L207.31 104.49L243.901 118.582L262.49 106.17L280.12 116.08V98.31L250.12 87.65V57.85L282.16 36.5L314.449 42.4611L314.66 28L338.66 11.75L339.03 45V45.13L358.91 45.25L339.41 59.38L339.16 107.63L355.99 115.83L333.91 130.33L313.16 121.63L314.41 62.38V45.13L314.446 42.6752L298.16 56L274.62 49L274.12 71.35L304.87 81.6L305.12 112.04L269.76 130.33L243.889 119.02L227.16 130.16L183.48 116.16L185.58 59.5L222.05 36.17ZM208.16 51.5L207.82 76L223.82 60.33L208.16 51.5Z" />
    </Box>    
  )
}